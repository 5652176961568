import http from '@/utils/http.js'

// 阿里报备添加
export function reportAdd(data) {
    return http({
        url: "/jasoboss/customer/ali/report/add",
        method: "post",
        data
    })
}
// 腾讯报备添加
export function tmReportAdd(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/add",
        method: "post",
        data
    })
}
// 阿里是试用添加
export function trialAdd(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/add",
        method: "post",
        data
    })
}
// 阿里报备列表
export function reportList(data) {
    return http({
        url: "/jasoboss/customer/ali/report/list",
        method: "post",
        data
    })
}
// 阿里试用列表

export function trialList(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/list",
        method: "post",
        data
    })
}
// 阿里报备域名列表
export function domainList(data) {
    return http({
        url: "/jasoboss/customer/ali/report/domain/list",
        method: "post",
        data
    })
}
// 阿里报备添加域名
export function domainAdd(data) {
    return http({
        url: "/jasoboss/customer/ali/report/domain/add",
        method: "post",
        data
    })
}
// 报备修改
export function aliReportEidt(data) {
    return http({
        url: "/jasoboss/customer/ali/report/update/detail",
        method: "post",
        data
    })
}
// 试用修改
export function aliTrialEidt(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/update/detail",
        method: "post",
        data
    })
}
// 更改试用
export function aliTrialChange(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/change/trial",
        method: "post",
        data
    })
}
// 阿里报备历史列表
export function aliReportHistoryList(data) {
    return http({
        url: "/jasoboss/customer/ali/report/history/list",
        method: "post",
        data
    })
}
// 阿里试用历史列表
export function aliTrialHistoryList(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/history/list",
        method: "post",
        data
    })
}
// // 阿里报备历史列表
// export function aliReportHistoryList(data) {
//     return http({
//         url: "/jasoboss/customer/ali/report/history/list",
//         method: "post",
//         data
//     })
// }
// // 阿里试用历史列表
// export function aliTrialHistoryList(data) {
//     return http({
//         url: "/jasoboss/customer/ali/trial/history/list",
//         method: "post",
//         data
//     })
// }