import http from '@/utils/http.js'

// 报备列表
export function reportList(data) {
    return http({
        url: "/jasoboss/customer/list/all",
        method: "post",
        data
    })
}
// 腾讯报备列表
export function tmReport(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/business/list",
        method: "post",
        data
    })
}
// 腾讯试用列表
export function tmTrial(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/business/list",
        method: "post",
        data
    })
}
// 腾讯报备审核
export function tmReportUpdate(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/update",
        method: "post",
        data
    })
}
// 腾讯试用审核
export function tmTrialUpdate(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/update",
        method: "post",
        data
    })
}
// 阿里报备列表
export function aliReport(data) {
    return http({
        url: "/jasoboss/customer/ali/report/business/list",
        method: "post",
        data
    })
}
// 阿里试用列表
export function aliTrial(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/business/list",
        method: "post",
        data
    })
}
// 阿里报备审核
export function aliReportUpdate(data) {
    return http({
        url: "/jasoboss/customer/ali/report/update",
        method: "post",
        data
    })
}
// 阿里试用审核
export function aliTrialUpdate(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/update",
        method: "post",
        data
    })
}

// 审核域名
export function domainUpdate(data) {
    return http({
        url: "/jasoboss/customer/ali/report/domain/update",
        method: "post",
        data
    })
}
// 腾讯延长，放弃报备审核
export function tmAudit(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/long/give/up/audit",
        method: "post",
        data
    })
}
// 腾讯延长，放弃试用审核
export function tmAuditTrial(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/long/stop/audit",
        method: "post",
        data
    })
}
// 阿里延长，放弃报备审核
export function aliAudit(data) {
    return http({
        url: "/jasoboss/customer/ali/report/long/give/up/audit",
        method: "post",
        data
    })
}
// 阿里延长，放弃试用审核
export function aliAuditTrial(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/long/stop/audit",
        method: "post",
        data
    })
}
// 腾讯报备主动修改
export function tmReportEdit(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/active/update",
        method: "post",
        data
    })
}
// 腾讯报备主动放弃
export function tmReportGive(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/active/give/up",
        method: "post",
        data
    })
}
// 腾讯试用主动修改
export function tmTrialEdit(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/active/update",
        method: "post",
        data
    })
}
// 腾讯试用主动放弃
export function tmTrialGive(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/active/stop",
        method: "post",
        data
    })
}
// 阿里报备主动修改
export function aliReportEdit(data) {
    return http({
        url: "/jasoboss/customer/ali/report/active/update",
        method: "post",
        data
    })
}
// 阿里报备主动放弃
export function aliReportGive(data) {
    return http({
        url: "/jasoboss/customer/ali/report/active/give/up",
        method: "post",
        data
    })
}
// 阿里试用主动修改
export function aliTrialEdit(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/active/update",
        method: "post",
        data
    })
}
// 阿里试用主动放弃
export function aliTrialGive(data) {
    return http({
        url: "/jasoboss/customer/ali/trial/active/stop",
        method: "post",
        data
    })
}