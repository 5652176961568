<template>
    <div class="trial">
        <el-form
            ref="form"
            style="flex:1;margin-top:20px;margin-left: 40px;"
            label-position="left"
            label-width="100px"
        >
            <el-form-item label="报备品牌">
                <span class="text">阿里企业邮箱</span>
            </el-form-item>
            <el-form-item label="公司名称">
                <span class="text">{{ form.companyName }}</span>
                <span @click="copyText(form.companyName )" style="margin-left:20px;color:#2370EB;cursor: pointer;">复制</span>
            </el-form-item>
            <el-form-item label="域名">
                <div class="domain-box">
                    <span>
                        <span class="text">{{ form.companyDomain }}</span>
                        <span @click="copyText(form.companyDomain)" style="margin-left:20px;color:#2370EB;cursor: pointer;">复制</span>
                    </span>
                    <p>
                        <span 
                            :class="{ isColor: activeIndex === 1 }"
                            @click="setDomain(1,form)"
                            >通过
                        </span>
                        <span 
                            :class="{ isColors: activeIndex === 2 }"
                            @click="setDomain(2,form)"
                            >驳回
                            </span>
                        </span>
                        <!-- <span v-if="form.domainAuditStatus != 0">
                            <span v-if="form.domainAuditStatus == 1" style="color:#2370EB;border:none">
                                通过
                            </span>
                            <span v-if="form.domainAuditStatus == 2" style="color:#D0021B;border:none">
                                驳回
                            </span>
                        </span> -->
                    </p>
                </div>
            </el-form-item>
            <el-form-item label="关联域名" v-for="(item, index) in form.customerAliReportDomainList"  v-if="item.domain!=''">
                <div class="domain-box">
                    <span>
                        <span class="text">{{ item.domain }}</span>
                        <span @click="copyText(item.domain )" style="margin-left:20px;color:#2370EB;cursor: pointer;">复制</span>
                    </span>
                    <p>
                        <span 
                            :class="{ isColor: item.activeIndex === 1 }"
                            @click="setActive(item, 1)"
                            >通过
                        </span>
                        <span 
                            :class="{ isColors: item.activeIndex === 2 }"
                            @click="setActive(item, 2)"
                            >驳回
                            </span>
                        </span>
                       
                    </p>
                </div>
            </el-form-item>
            <el-form-item label="申请备注">
                <span class="text">{{ form.remark }}</span>
            </el-form-item>
            <el-form-item label="审核结果" v-if="disabled">
                <span class="text">
                    <el-radio v-model="auditStatus" label="1">报备通过</el-radio>
                    <el-radio v-model="auditStatus" label="2">报备驳回</el-radio>
                </span>
            </el-form-item>
            <el-form-item label="到期时间" required  style="margin-left:-10px"  v-if="auditStatus==1">
                <el-date-picker
                    v-model="aliReportExpireTime"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width:270px;margin-left:10px"
                    type="date"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="auditStatus == 1">
                <el-radio-group v-model="expireTime" size="small">
                    <el-radio-button
                        label="30天"
                        @click.native.prevent="clickTime('30天')"
                    ></el-radio-button>
                    <el-radio-button
                        label="60天"
                        @click.native.prevent="clickTime('60天')"
                    ></el-radio-button>
                    <el-radio-button
                        label="90天"
                        @click.native.prevent="clickTime('90天')"
                    ></el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="审核备注" v-if="auditStatus == 1">
                <el-input
                    type="textarea"
                    :rows="2"
                    style="width:270px"
                    placeholder="请输入内容"
                    v-model="remark"
                >
                </el-input>
            </el-form-item>
            <el-form-item
                label="驳回原因"
                required
                style="margin-left:-10px"
                v-if="auditStatus == 2"
            >
                <el-input
                    type="textarea"
                    :rows="2"
                    style="width:270px;margin-left:10px"
                    placeholder="请输入内容"
                    v-model="rejectReason"
                >
                </el-input>
            </el-form-item>
            <el-form-item label="报备库" v-if="auditStatus == 1 && disabled">
                <el-select v-model="libraryCategory" size="small" style="width:270px" placeholder="请选择活动区域">
                <el-option label="伽搜" value="1"></el-option>
                <el-option label="云梦" value="2"></el-option>
                <el-option label="华中" value="3"></el-option>
                <el-option label="山总" value="4"></el-option>
                <el-option label="畅搜" value="5"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left:270px"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;"
                size="mini"
                @click="onSubmit"
                >确认</el-button
            >
        </div>
    </div>
</template>
<script>
import { aliReportUpdate, domainUpdate } from '@/api/user/business/customer';
import { updateDomain } from '@/api/user/ae/customer';
import { reportList } from '@/api/user/ae/ali';
export default {
    data() {
        return {
            form: {},
            customerDomainList: [
                {
                    domain: '',
                },
            ],
            auditStatus: '1',
            aliReportExpireTime: '',
            rejectReason: '',
            expireTime:'30天',
            id: '',
            remark: '',
            isColor: false,
            isColors: false,
            disabled: true,
            timeDisabld:true,
            activeIndex:0,
            libraryCategory:'1',
        };
    },
    methods: {
        echoData(data) {
            this.disabled =true
            this.auditStatus = '1';
            this.aliReportExpireTime = '';
            this.rejectReason = '';
            this.remark = '';
            this.id = data.customerId;
            if(data.aliReportExpireTime){
                this.aliReportExpireTime = data.aliReportExpireTime
                this.expireTime=''
            }else{
                let date = new Date();
                this.aliReportExpireTime = this.$searchTime.getNextDate(date, -30)+' 00:00:00'
            }
            let datas = {
                param: {
                    customerId: data.customerId,
                },
            };
            reportList(datas).then((res) => {
                // console.log(res);
                this.activeIndex=res.data.list[0].domainAuditStatus
                for (let i in res.data.list[res.data.list.length - 1].customerAliReportDomainList) {
                    if (
                        res.data.list[res.data.list.length - 1].customerAliReportDomainList[i]
                            .auditStatus != 0
                    ) {
                        res.data.list[res.data.list.length - 1].customerAliReportDomainList[
                            i
                        ].activeIndex =
                            res.data.list[res.data.list.length - 1].customerAliReportDomainList[
                                i
                            ].auditStatus;
                    }
                }
                this.form = res.data.list[res.data.list.length - 1];
                for (let i in res.data.list[res.data.list.length - 1].customerAliReportDomainList) {
                    if (
                        res.data.list[res.data.list.length - 1].customerAliReportDomainList[i]
                            .auditStatus == 1
                    ) {
                        return (this.disabled = false);
                    }
                }
            });
        },
        setActive(item, num) {
            this.$set(item, 'activeIndex', num);
            let data = {
                param: {
                    id: item.id,
                    auditStatus: num,
                },
            };
            domainUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                } 
            });
        },
        setDomain(i, row){
            this.activeIndex =i
            let data={
                param:{
                    customerId:row.customerId,
                    customerAliReportDO:{
                        domainAuditStatus:i
                    }
                }
            }
            updateDomain(data).then(res=>{
                if (res.code == 200) {
                    this.$message.success('操作成功');
                } 
            })
        },
        onSubmit() {
            if (this.disabled) {
                let data = {
                    param: {
                        customerId: this.id,
                        customerAliReportDO: {
                            auditStatus: this.auditStatus,
                        },
                    },
                };
                if (this.auditStatus == 1) {
                    if (!this.aliReportExpireTime) {
                        return this.$message.error('请选择到期时间');
                    } else {
                        data.param.customerAliReportDO.aliReportExpireTime = this.aliReportExpireTime;
                        data.param.customerAliReportDO.auditRemark = this.remark;
                        data.param.customerAliReportDO.libraryCategory = this.libraryCategory;
                    }
                } else if (this.auditStatus == 2) {
                    if (!this.rejectReason) {
                        return this.$message.error('请输入驳回原因');
                    } else {
                        data.param.customerAliReportDO.rejectReason = this.rejectReason;
                    }
                }
                if(this.activeIndex == 0 ){
                    return this.$message.error('请先审核域名');
                }
                for (let i in this.form.customerAliReportDomainList) {
                    if (!this.form.customerAliReportDomainList[i].activeIndex &&this.form.customerAliReportDomainList[i].domain!='') {
                        return this.$message.error('请先审核域名');
                    }
                }
                aliReportUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('审核成功');
                        this.onClose();
                    } 
                });
            } else {
                for (let i in this.form.customerAliReportDomainList) {
                    if (!this.form.customerAliReportDomainList[i].activeIndex) {
                        return this.$message.error('请先审核域名');
                    }
                }
                if(!this.aliReportExpireTime ){
                    return this.$message.error('请选择到期时间');
                }
                let data = {
                    param: {
                        customerId: this.id,
                        customerAliReportDO: {
                            auditStatus: 1,
                            aliReportExpireTime: this.aliReportExpireTime,
                            auditRemark:this.remark,
                            libraryCategory:this.libraryCategory
                        },
                    },
                };
                aliReportUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('审核成功');
                        this.onClose();
                    } 
                });
            }
        },
        // 到期时间点击
        clickTime(e) {
            // console.log(e);
            e === this.expireTime ? (this.expireTime = '') : (this.expireTime = e);
            let date = new Date();
            if(e == this.expireTime && e=='30天'){
                this.aliReportExpireTime  =this.$searchTime.getNextDate(date, -30)+' 00:00:00'  ;
            }else if(e == this.expireTime && e=='60天'){
                this.aliReportExpireTime  = this.$searchTime.getNextDate(date, -60)+' 00:00:00';
            }else if(e == this.expireTime && e=='90天'){
                this.aliReportExpireTime  = this.$searchTime.getNextDate(date, -90)+' 00:00:00';
            }else{
                this.aliReportExpireTime  =''
            }
        },
        onClose() {
            this.$emit('close');
            this.aliReportExpireTime = ''
            this.remark=''
            this.rejectReason=''
        },
        //复制
        copyText(data){
            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message({
            message: '复制成功',
            type: 'success'
            });
            oInput.remove()
        }
    
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder ,/deep/ .el-range-input::-webkit-input-placeholder{
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.trial {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        background: #fafafa;
        margin-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-input {
        width: 270px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
    .domain-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
            margin-right: 20px;
            display: flex;
            span {
                display: inline-block;
                width: 48px;
                height: 20px;
                background: #ffffff;
                line-height: 20px;
                text-align: center;
                font-weight: 400;
                color: #666666;
                font-size: 12px;
                border-radius: 1px 0px 0px 1px;
                border: 1px solid #e5e5e5;
                cursor: pointer;
            }
            .isColor {
                color: #2370EB;
                border-color: #2370EB;
            }
            .isColors {
                color: #d0021b;
                border-color: #d0021b;
            }
        }
    }
}
</style>
